import HttpRequest from './core/http_request';

class AdminProvider extends HttpRequest {
  addAdmin (data) {
    return this.request('post', '/webapp/admin', data);
  }

  getAdminList (data) {
    return this.request('get', '/webapp/admin', data);
  }

  getAdminByID (data) {
    return this.request('get', '/webapp/admin/id/' + data._id);
  }

  updateAdmin (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/admin/id/' + id, data);
  }

  deleteAdmin (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/webapp/admin/delete/' + id);
  }
}

export default AdminProvider;
