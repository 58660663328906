import HttpRequest from './core/http_request';

class MasterProvider extends HttpRequest {
  getListActor () {
    return this.request('get', '/master/actor');
  }

  getListViolence () {
    return this.request('get', '/master/violence');
  }

  amphurByID (data) {
    const id = data.id;
    return this.request('get', '/common/amphurID/' + id);
  }
}

export default MasterProvider;
