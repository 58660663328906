import HttpRequest from './core/http_request';

class ReportProvider extends HttpRequest {
  getListReport (data) {
    return this.request('get', '/report', data);
  }

  getByProvince (data) {
    const id = data.id;
    delete data._id;
    return this.request('get', '/report/byAmphur/' + id, data);
  }
}

export default ReportProvider;
