import HttpRequest from './core/http_request';

class UploadfileProvider extends HttpRequest {
  getStatus () {
    return this.request('get', '/uploadFile/status');
  }

  uploadImg (data) {
    return this.request('upload', '/uploadFile/uploadImg', data);
  }

  getFile (data) {
    return this.request('get', '/uploadFile/getFile', data);
  }
}

export default UploadfileProvider;
