import HttpRequest from './core/http_request';

class IncidentProvider extends HttpRequest {
  addIncident (data) {
    return this.request('post', '/incident', data);
  }

  updateIncident (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/incident/id/' + id, data);
  }

  getIncident (data) {
    const id = data._id;
    delete data._id;
    return this.request('get', '/incident/id/' + id);
  }

  getList (data) {
    return this.request('get', '/incident', data);
  }

  deleteIncident (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/incident/delete/' + id);
  }

  genIncidentCode () {
    return this.request('get', '/incident/gencode');
  }
}

export default IncidentProvider;
