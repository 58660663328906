<template>
  <div class="modal fade modalBgOp60" id="loadingModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalxLabel"
    aria-hidden="true" style="z-index: 1056;">
    <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered" role="document">
      <div class="d-flex flex-column mx-5 mt-3 mb-5 justify-content-center w-100">
        <div class="spinner-border text-warning align-self-center loader-lg">
          Loading...
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import eventBus from '@/eventBus/eventBus.js';
 
  // variable
  const loadingModal = ref(null);
  // end variable

  // function
  const showLoading = () => {
    if (!loadingModal.value) {
      loadingModal.value = new bootstrap.Modal(document.getElementById('loadingModal'), {
        backdrop: 'static',
        keyboard: false
      });
    } 
    
    loadingModal.value.show();
  }

  const hideLoading = () => {
    if (loadingModal.value) {
      loadingModal.value.hide();
    }
  }
  // end function

  onMounted(() => {
    loadingModal.value = new bootstrap.Modal(document.getElementById('loadingModal'), {
      backdrop: 'static',
      keyboard: false
    });

    // init event bus
    eventBus.on('showLoadingModal', () => {
      showLoading();
    });

    eventBus.on('hideLoadingModal', () => {
      hideLoading();
    });
  });

  defineExpose({
    showLoading,
    hideLoading
  });
</script>